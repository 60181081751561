// Main javascript file

// import styles for webpack build
import './scss/styles.scss';
// import './checkout.js';
// import './beheer.js';


import flatpickr from 'flatpickr';
import { Dutch } from "flatpickr/dist/l10n/nl.js"

import $ from 'cash-dom';
import axios from 'axios';
// import objectFitImages from 'object-fit-images';
import inView from 'in-view';
// import Rellax from 'rellax';
// import SmoothScroll from 'smooth-scroll';
import noScroll from 'no-scroll';
import { Fancybox } from "@fancyapps/ui";

import dayjs from 'dayjs'
import 'dayjs/locale/nl';
dayjs.locale('nl');

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

import {SearchBox} from './js/filterbox.js';
import {CookieBar} from './js/cookiebar.js';


// map cash-dom to jQuery
const jQuery = $;

window.$ = $;
window.dayjs = dayjs;
window.flatpickr = flatpickr;


function onFormSubmit(ev) {
  ev.preventDefault();
  ev.stopPropagation();

  const $form = $(this);

  console.debug([this, $form, ev.target, $form[0]]);

  // disable submit button
  $form.find('button[type="submit"]').prop('disabled', true);

  grecaptcha.execute(app.captcha.key, { action: 'submit' }).then(function (token) {
    axios
      .post(app.captcha.verify_url, { token: token })
      .then(function (result) {
        console.info('got verify response: ' + result.status);
        $form.off('submit', onFormSubmit);
        console.info('submit form,..');
        $form[0].submit();
      })
      .catch(function (err) {
        console.error(err);
        $form.find('button[type="submit"]').prop('disabled', false);
      });
  });

  return false;
}


(function ($) {


  // dynamicly add google recaptcha
  var script = document.createElement('script');
  document.body.appendChild(script);
  script.onload = function (e) {
    grecaptcha.ready(function() {
      console.info('captcha ready!');
      const forms = $('form[method="POST"]');
      $(forms).on('submit', onFormSubmit);
    });
  };

  script.src = "https://www.google.com/recaptcha/api.js?render="+ app.captcha.key

  /*=================================
  =            Accordion            =
  =================================*/

  function setActiveTab($elem) {
    checkDesktopTab($elem);

    // Change icon plus to minus
    if ($elem.parent().hasClass('active')) {
      $elem.attr('aria-expanded', 'true');
    } else {
      $elem.attr('aria-expanded', 'false');
    }
  }

  // Check whehter is Desktop variant
  function checkDesktopTab($elem) {
    if (
      $('#js-tabs-component').length > 0 &&
      $('#js-tabs-wrapper').css('display') == 'flex'
    ) {

      let $parent = $elem.parent('.tab-item'),
          $tabs = $parent.siblings();

      // Reset aria expanded
      $('.accordion-title').attr('aria-expanded', 'false');

      // remove class active from all tabs
      $tabs.removeClass('active');

      // add class active to parent of current active element
      $parent.addClass('active');
    }
    else {
      $elem.parent().toggleClass('active');
    }

    // Invoke function to calculate height Tab
    calculateHeightTab();
  }

  function calculateHeightTab() {
    // Check whether is Desktop variant
    if (
      $('#js-tabs-component').length > 0 &&
      $('#js-tabs-wrapper').css('display') == 'flex'
    ) {
      const parent = $('#js-tabs-component');
      // Reset height / margin
      parent.css({ 'height': 'auto', 'marginBottom': 0 });
      // Calculate height tab content
      const originalHeight = parent.find('.tabs').height();
      const contentHeight = parent
        .find('.active .tab-inner')
        .outerHeight(true);
      parent.css('height', contentHeight + originalHeight + 36);
    } else {
      $('#js-tabs-component').css('height', 'auto');
    }
  }

  function resetClassesTabDesktop() {
    // Check whether is Desktop variant
    if (
      $('#js-tabs-component').length > 0 &&
      $('#js-tabs-wrapper').css('display') == 'flex'
    ) {
      $('#js-tabs-wrapper .active')
        .removeClass('active')
        .find('.accordion-title')
        .attr('aria-expanded', 'false');

      $('#js-tabs-wrapper li')
        .first()
        .addClass('active')
        .find('.accordion-title')
        .attr('aria-expanded', 'true');
    }
  }


  /*=====================================
  =           Video player              =
  =====================================*/

  // play the targeted video (and hide the poster frame)
  function videoPlay($wrapper) {
    var $iframe = $wrapper.find('.js-videoIframe');
    var src = $iframe.data('src');
    // hide poster
    $wrapper.addClass('videoWrapperActive');
    // add iframe src in, starting the video
    $iframe.attr('src', src);
  }

  // stop the targeted/all videos (and re-instate the poster frames)
  function videoStop($wrapper) {
    // if we're stopping all videos on page
    if (!$wrapper) {
      var $wrapper = $('.js-videoWrapper');
      var $iframe = $('.js-videoIframe');
      // if we're stopping a particular video
    } else {
      var $iframe = $wrapper.find('.js-videoIframe');
    }
    // reveal poster
    $wrapper.removeClass('videoWrapperActive');
    // remove youtube link, stopping the video from playing in the background
    $iframe.attr('src', '');
  }


  /*========================================
  =            Read more toggle            =
  ========================================*/

  function readMore(maxLength = 500) {
    $('.js-read-more').each(function () {
      // reset items
      // first unwrap read-more__container
      $(this)
        .find('.read-more__container')
        .contents()
        .unwrap();

      // then remove fade container
      $(this)
        .find('.read-more__fade')
        .remove();

      var contentBlock = $(this); // save this as contentBlock
      var content = $(this).html(); // Get content from container
      var blockHeight = $(this).height(); // Get real height of content container

      // Check if content exceeds entered max length
      if (content.length > maxLength) {
        // Wrap in new container with height limit
        $(this).wrapInner('<div class="read-more__container"></div>');
        // Create div to fade content
        $(this).append(
          '<div class="read-more__fade"><a class="read-more__button">Toon meer</a></div>'
        );
      }

      contentBlock.on('click', '.read-more__button', function (e) {
        e.preventDefault();
        contentBlock.addClass('active');
        contentBlock.find('.read-more__container').animate(
          {
            height: blockHeight,
          },
          300,
          function () {
            // Remove  read more ability from the DOM
            contentBlock.find('.read-more__fade').remove();
          }
        );
      });
    });
  }


  function showReceiptOverlay() {
    const receiptOverlay = document.querySelector('.js-receipt-overlay');
    const header = document.querySelector('.site-header');

    if (receiptOverlay.classList.contains('is-active')) {
      noScroll.off();
      receiptOverlay.classList.remove('is-active');
      setTimeout(() => header.style.removeProperty('z-index'), 1000);
    } else {
      noScroll.on();
      receiptOverlay.classList.add('is-active');
      header.style.zIndex = 0;
    }
  };


  /*==============================
  =         Truncate text       =
  ==============================*/
  function truncate(elem, limit, after) {
    // Make sure an element and number of items to truncate is provided
    if (!elem || !limit) return;

    const $elem = $(elem),
          originalContent = $elem.html();

    // $elem.after($('<div class="original">'+ originalContent +'</div>'));

    let content = $elem.text().trim();

    // Convert the content into an array of words & remove any words above the limit
    content = content.split(" ").slice(0, limit);

    // Convert the array of words back into a string. If there's content to add after it, add it
    content = content.join(" ") + (after ? after : "");

    // Inject the content back into the DOM
    $elem.html(content);

    // Show original content on click
    $(elem).find('a').on('click', (e) => {
      // $(e.target).parent('[data-truncate]').hide().next('div.original').show();
      $(e.target).parent('[data-truncate]').html(originalContent.replace(/hide-mobile/gi, ''));
    });
  }

  function toggleMobilePriceCard(readMoreMobileButtons) {
    readMoreMobileButtons.each((idx, button) => {
      $(button).on('click', () => {

        const parent = button.previousElementSibling;
        const wrapper = parent.querySelector('.price-card__details-wrapper');
        const parentHeight = parent.clientHeight;
        const wrapperHeight = wrapper.clientHeight;

        // toggle aria attributes
        if (parent.classList.contains('expanded')) {
          parent.removeAttribute('aria-hidden');
          parent.classList.remove('expanded');
          button.removeAttribute('aria-expanded');
        } else {
          parent.classList.add('expanded');
          parent.setAttribute('aria-hidden', true)
          button.setAttribute('aria-expanded', true);
        }

        // Animate to height of the div
        if (parentHeight === 0) {
          parent.style.maxHeight = `${wrapperHeight}px`;
          button.innerText = 'Verberg extra informatie';
          button.classList.add('expanded');
        } else {
          parent.style.maxHeight = '0px';
          button.innerText = 'Toon meer informatie';
          button.classList.remove('expanded');
        }
      });
    });
  }


  /*===================================
  =         Wrap helper function      =
  ===================================*/

  function wrapElement(el, wrapper, firstClass, secondClass) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    wrapper.classList.add(firstClass);
    if (secondClass) wrapper.classList.add(secondClass);
  }


  function stickIt(windowScrollPositionY) {
    // console.info('stickIt called');
    // console.info('pos: '+ windowScrollPositionY);

    var orgElement = $('.original'),
        orgElementPos = orgElement.offset(),
        orgElementTop = orgElementPos.top,
        orgElementLeft = orgElementPos.left,
        orgElementWidth = orgElement.css('width');

    // console.info([orgElementTop, windowScrollPositionY, window.clone_took_over]);

    if (windowScrollPositionY >= (orgElementTop) && !window.clone_took_over) {
      $('.cloned')
        .css('left', orgElementLeft + 'px')
        .css('width', orgElementWidth)
        .show();

      $('.original').css('visibility', 'hidden');

      window.clone_took_over = true;
    }
    else if (windowScrollPositionY < (orgElementTop) && window.clone_took_over) {
      // not scrolled past the menu; only show the original menu.
      $('.cloned').hide();
      $('.original').css('visibility', 'visible');

      window.clone_took_over = false;
    }
  }


  function initializeStickyMenu() {

    if (
        $('#js-tabs-component').length <= 0 ||
        $('#js-tabs-wrapper').css('display') != 'flex'
    ) {
        console.info('no need for sticky menu..');
        return;
    }

    var $cloned = $('#js-tabs-wrapper');

    if ($cloned.length <= 0) {
      console.info('no tabs to stick found..');
      return;
    }

    var $cloned = $('#js-tabs-wrapper')
      .addClass('original')
      .clone()
      .insertAfter('#js-tabs-wrapper')
      .css('position','fixed')
      .css('top','0')
      .css('margin-top','0')
      .css('margin-left', '0')
      .css('z-index','600')
      .addClass('cloned')
      .addClass('u-hidden@md-down')
      .removeClass('original')
      .hide()
      .attr('id', 'js-tabs-wrapper-cloned')
      .find('.tab-inner')
      .remove();

    var orgElement = $('.original'),
        coordsOrgElement = orgElement.offset(),
        leftOrgElement = coordsOrgElement.left,
        widthOrgElement = orgElement.css('width');

    $cloned
      .css('left', leftOrgElement + 'px')
      .css('top', 0)
      .css('width', widthOrgElement);

    // console.info($cloned);

    // var scrollIntervalID = setInterval(stickIt, 100);  // set update interval..
    window.clone_took_over = false;
    window.last_known_scroll_position_y = 0;
    window.ticking = false;

    $(window).on('scroll', (e) => {
      window.last_known_scroll_position_y = window.scrollY;
      if (!window.ticking) {
        window.requestAnimationFrame(() => {
          stickIt(window.last_known_scroll_position_y);
          window.ticking = false;
        });

        window.ticking = true;
      }
    });
  }


  function toggleFilter(type='open') {
    const mobileFilterButton = $('.filter__button'),
          mobileFilterClose = $('.filter__close'),
          mobileFilterBlocks = $('.filter__blocks'),
          header = $('.site-header'),
          buttonText = mobileFilterButton.html();

    type === 'close' ? mobileFilterBlocks.removeClass('open') : mobileFilterBlocks.addClass('open');

    if (mobileFilterBlocks.hasClass('open')) {
      mobileFilterButton.hide();
      noScroll.on();
      header.css('z-index', 0);
    } else {
      setTimeout(() => {
        header.css('z-index', 'unset')
        mobileFilterButton.show();
        noScroll.off();
      }, 200);
    }
  };


  $(document).ready(function () {
    console.info('document.ready');

    try {
      new CookieBar({
        'container': '#crusj-cookie-consent-bar',
        'explicitacceptcontrol': '#crusj-cookies-accept'
      });
    }
    catch (err) {
      console.info(err);
    }

    // setup sticke menu
    initializeStickyMenu();

    // setup read more
    readMore(500);

    // lazy load images with inView
    inView('img.lazy').on('enter', (elm) => {
      var $elm = $(elm);
      if (!$elm.hasClass('lazy') || (elm.src && !$elm.data('src'))) {
        // console.info(['not loading', elm]);
        return;
      }

      console.info('loading lazy img => '+ ($elm.data('src') || elm.src));

      $elm.removeClass('lazy').addClass('lazy-loaded');
      elm.src = $elm.data('src');
      $elm.removeAttr('data-src');
    });

    // video poster frame click event
    $('.js-videoPoster').on('click', function (e) {
      e.preventDefault();
      var $poster = $(this);
      var $wrapper = $poster.closest('.js-videoWrapper');
      videoPlay($wrapper);
    });

    // initialize flatpickr date pickets
    if ($('.date').length >= 1) {
      flatpickr('.date', {
        minDate: 'today',
        weekNumbers: true,
        dateFormat: 'd M Y',
        locale: Dutch,
      });
    }

    // parralax-like animatie on desktop
    // if ($('.rellax').length >= 1 && window.innerWidth >= 768) {
    //   console.info('initiale rellax');
    //   var rellax = new Rellax('.rellax');
    // }


    // truncate elements and enable read more on those elements
    $("[data-truncate]").each((idx, el) => {
      const length = $(el).attr("data-truncate");
      truncate(el, length, '... <a style="cursor:pointer">Lees meer</a>');
    });


    // Fade up effect wanneer in viewport
    // TODO: replace inView for vanilla js
    inView('.review').on('enter', () => {
      $('.review > .row > li:nth-child(1)').addClass('fadeInUp');
      $('.review > .row > li:nth-child(2)').addClass('fadeInUp');
    });

    // Show price of trip on detail page
    const offer = $('.sticky-offer');
    if (offer.length >= 1) {
      inView('.travel-summary, .location, .detail-info, .testimonial').on('enter', () => {
        offer.addClass('open');
      });
      inView('.results__list, .hero, .single-intro__left').on('enter', () => {
        offer.removeClass('open');
      });
    }


    /*===================================
    =    reizen overview toggles        =
    ===================================*/
    const readMoreMobileButtons = $('.price-card__button');

    // Desktop
    const toggleButtons = document.querySelectorAll('.js-toggleList');
    toggleButtons.forEach(btn => {
      btn.addEventListener('click', () => {
        setTimeout(() => calculateHeightTab(), 100);

        const parentList = btn.parentNode.parentNode;
        parentList.classList.toggle('active');

        if (/groepsinfo|verberg/i.test(btn.innerHTML)) {
          const btnText = /groepsinfo/i.test(btn.innerHTML) ? 'Verberg' : 'Groepsinfo';
          btn.innerHTML = btnText;
        }

      });
    });

    const toggleAttendeesBtn = document.querySelector('.js-toggle-attendees');
    if (toggleAttendeesBtn) {
      toggleAttendeesBtn.addEventListener('click', () => {
        setTimeout(() => calculateHeightTab(), 250);
        toggleAttendeesBtn.classList.toggle('expanded');

        // Change text clicked button
        const AttendeesBtnText = toggleAttendeesBtn.innerHTML === 'Toon alle deelnemers' ? 'Verberg deelnemers' : 'Toon alle deelnemers';
        toggleAttendeesBtn.innerHTML = AttendeesBtnText;

        // Toggle class from all price cards desktop
        toggleButtons.forEach(btn => {
          const parentList = btn.parentNode.parentNode;
          const btnText = btn.innerHTML;

          if (toggleAttendeesBtn.classList.contains('expanded')) {
            parentList.classList.add('active');
            btn.innerHTML = 'Verberg';
          } else {
            parentList.classList.remove('active');
            btn.innerHTML = 'Groepsinfo';
          }
        });

        // // Toggle class for mobile price cards
        // if (readMoreMobileButtons.length >= 1) {
        //   readMoreMobileButtons.forEach(button => {
        //     const parent = button.previousElementSibling;
        //     const wrapper = parent.querySelector('.price-card__details-wrapper');
        //     const parentHeight = parent.clientHeight;
        //     const wrapperHeight = wrapper.clientHeight;

        //     if (toggleAttendeesBtn.classList.contains('expanded')) {
        //       // toggle aria attributes
        //       parent.removeAttribute('aria-hidden');
        //       parent.classList.remove('expanded');
        //       button.removeAttribute('aria-expanded');
        //       parent.style.maxHeight = `${wrapperHeight}px`;
        //       button.innerText = 'Verberg extra informatie';
        //       button.classList.add('expanded');
        //     } else {
        //       parent.classList.add('expanded');
        //       parent.setAttribute('aria-hidden', true)
        //       button.setAttribute('aria-expanded', true);
        //       parent.style.maxHeight = '0px';
        //       button.innerText = 'Toon meer informatie';
        //       button.classList.remove('expanded');
        //     }
        //   });
        // };

      });
    };

    // Mobile

    if (readMoreMobileButtons.length >= 1) {
      toggleMobilePriceCard(readMoreMobileButtons);
    };

    // Collapse / close de filters op mobile
    $('#toggleFilter').on('click', () => {
      $('#filterOptions').toggleClass('collapsed');
      $(this).toggleClass('active');
    });

    $('#toggleActive').on('click', () => {
      $('#filterActive').toggleClass('collapsed');
      $(this).toggleClass('active');
    });

    $('.filter__button').on('click', () => toggleFilter());
    $('.filter__close').on('click', () => toggleFilter('close'));


    /*===================================
    =            Slideshow count         =
    ===================================*/
    const slideshows = $('[data-slideshow]');
    if (slideshows.length >= 1) {
      slideshows.each((idx, slideshow) => {
        const galleryThumbs = $(slideshow).find('.gallery-thumbs a');
        const slides = $(slideshow).find('a');
        if (galleryThumbs.length >= 1) {
          galleryThumbs[1].setAttribute(
            'data-content',
            `+ ${galleryThumbs.length - 1} foto's`
          );
        } else if (slides.length > 3) {
          slides[2].setAttribute('data-content', `+ ${slides.length - 2} foto's`);
        }
      });
    }

    // ===================================
    // =            Smoothscroll         =
    // ===================================
    /* nu via css "scroll-behaviour: smooth" */
    // var scroll = new SmoothScroll('a[href*="#"]:not(.no-scroll)', {
    //   offset: 100,
    // });

    /*===================================
    =          Overlay Receipt         =
    ===================================*/

    $('.js-receipt-trigger').on('click', () => {
      $('.js-receipt-trigger').toggleClass('active');
      showReceiptOverlay();
    });


    // Wrap iframe in a div 'container' to apply the correct styling
    $('main iframe:not(.videoIframe)').each((idx, iframe) => {
      wrapElement(iframe, document.createElement('div'), 'iframe-container');
    })


    /*===================================
    =            Search toggle         =
    ===================================*/

    const searchTrigger = document.querySelector('[data-search-trigger]');
    const searchField = document.querySelector('.mobile-search');

    if (searchTrigger) {
      searchTrigger.addEventListener('click', () => {
        const icon = searchTrigger.getAttribute('data-icon');

        // slide in the input field & hide the other icons from the nav-bar
        searchField.classList.toggle('active');
        searchTrigger.previousElementSibling.classList.toggle('hidden');
        searchTrigger.previousElementSibling.previousElementSibling.classList.toggle(
          'hidden'
        );

        // Update the icon to a cross
        icon === 'e'
          ? searchTrigger.setAttribute('data-icon', 'S')
          : searchTrigger.setAttribute('data-icon', 'e');
      });
    }

    // initialize mobile menu
    $('.click').on('click', function () {
      $('#burger').toggleClass('active');

      if ($('#burger').hasClass('active')) {
        $('.mobile-menu').css('width', '100vw');
        $('.mobile-menu li').show();

        $('.nav-bar').css('z-index', 'auto');
        $('body').addClass('has-overlay');

        setTimeout(function () {
          $('.mobile-menu li').css('opacity', '1');
          $('.mobile-menu li').css('transform', 'translateY(0px)');
        }, 200);
      } else {
        $('.mobile-menu').css('width', '0px');
        $('.mobile-menu li').css('opacity', '0');
        $('.nav-bar').css('z-index', 2);
        $('body').removeClass('has-overlay');
        setTimeout(function () {
          $('.mobile-menu li').hide();
        }, 500);
      }
      return false;
    });

    $(document).on('click', function () {
      if ($('#burger').hasClass('active')) {
        $('.mobile-menu').width('0px');
        $('.mobile-menu li').css('opacity', '0');
        setTimeout(function () {
          $('.mobile-menu li').hide();
        }, 500);
        $('#burger').removeClass('active');
      }
    });

    function activate_accordion_tab (e) {
      // console.info('accordion clicked');
      const elem = $(this);

      if (elem.hasClass('accordion-btn')) {
        console.info('elements has accordion-btn class.. skipping');
        return;
      }

      // stop default event
      e.stopPropagation();
      e.preventDefault();

      var $elem = $('#js-tabs-wrapper a[href="'+ elem.attr('href') +'"]');
      setActiveTab($elem);

      $('#js-tabs-wrapper-cloned .tab-item').removeClass('active');
      $('#js-tabs-wrapper-cloned a[href="'+ elem.attr('href') +'"]').parent('.tab-item').addClass('active');

      if (elem.parents('.tabs').hasClass('cloned') || elem.hasClass('js-tab-link')){
        $elem.get(0).scrollIntoView();
      }
    }

    // initialize accordion
    $('.accordion').on('click', '.accordion-title', activate_accordion_tab);
    $('.js-tab-link').on('click', activate_accordion_tab);

    resetClassesTabDesktop();
    calculateHeightTab();

    // if there is a hash in the url, truy to activate that tab:
    if(document.location.hash) {
      var $elem = $('#js-tabs-wrapper a[href="'+ document.location.hash +'"]');
      if ($elem) {
        // window.setTimeout(() => {
          setActiveTab($elem);
        // }, 500);
      }
    }


    if ($('div.holidays-filter-box.filters-box--home').length > 0) {
      var filters = [
        // {
        //   'search': 'min_age',
        //   'source': 'min_age',
        //   'display_type': 'select'
        // },
        // {
        //   'search': 'max_age',
        //   'source': 'max_age',
        //   'display_type': 'select'
        // },
        {
          'search': 'departure_month',
          'source': 'departure_month',
          'display_type': 'select'
        },
      ];

      var container = $('div.holidays-filter-box');
      var box = new SearchBox(container[0], {
          'endpoint': '/single-reizen/filters',
          'load': '/single-reizen/',
          'reset': false,
          'filters': filters,
          'show_departure_as_holidays': true,
          onUpdateFilters: function (name, value) {
              try{
                  console.info('sending event: '+ name +' - '+ value);
                  ga('send', {
                      'hitType': 'event',
                      'eventCategory': 'Filters homepage',
                      'eventAction': name,
                      'eventLabel': value
                  });
              }
              catch(err) { console.info(err); }
          },
          onAfterUpdateFilters: function(name, value) {
              this.reloadPage();
          }
      });
    }


    if ($('#js-mobile-filters').length > 0) {
      var filters = [

        {
          'search': 'departure_month',
          'source': 'departure_month',
          'display_type': 'select'
        },

        {
          'search': 'departure_guarantee',
          'source': 'departure_guarantee',
          'display_type': 'boolean'
        },

        {
          'search': 'country',
          'source': 'country',
          'display_type': 'select'
        },

        {
          'search': 'category',
          'source': 'category',
          'display_type': 'select'
        },

        {
          'search': 'boardtype',
          'source': 'boardtype',
          'display_type': 'select'
        },

        {
          'search': 'roomtype',
          'source': 'roomtype',
          'display_type': 'select'
        },

        {
          'search': 'min_age',
          'source': 'min_age',
          'display_type': 'select'
        },

        {
          'search': 'max_age',
          'source': 'max_age',
          'display_type': 'select'
        },

      ];

      var container = $('#js-mobile-filters');
      var box = new SearchBox(container[0], {
          'endpoint': '/single-reizen/filters',
          'load': '/single-reizen/',
          'reset': true,
          'filters': filters,
          'show_departure_as_holidays': true,
          onUpdateFilters: function (name, value) {
              try{
                  console.info('sending event: '+ name +' - '+ value);
                  ga('send', {
                      'hitType': 'event',
                      'eventCategory': 'Filters listerpage mobile',
                      'eventAction': name,
                      'eventLabel': value
                  });
              }
              catch(err) { console.info(err); }
          }
      });
    }


    /* Scroll to top */
    $('a.scroll').on('click', function () {
      $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top,
      },500);
      return false;
    });

    $('.js-program-title').on('click', function (e) {
      const elem = $(this);

      elem.closest('.program__item').toggleClass('active');

      // Change icon plus to minus
      if (elem.closest('.program__item').hasClass('active')) {
        elem.attr('aria-expanded', 'true');
      } else {
        elem.attr('aria-expanded', 'false');
      }

      const parent = $('#js-tabs-component');

      // Calculate height tab content
      const originalHeight = parent.find('.tabs').height();
      const contentHeight = parent.find('.active .tab-inner').outerHeight(true);
      parent.css('height', contentHeight + originalHeight + 36);

      e.preventDefault();
    });

    $('.accordion-faq .accordion-title, .accordion--footer .accordion-title').on('click', function (e) {
      const elem = $(this);
      console.info(elem);

      elem.parent().toggleClass('active');

      // Change icon plus to minus
      if (elem.parent().hasClass('active')) {
        elem.attr('aria-expanded', 'true');
      } else {
        elem.attr('aria-expanded', 'false');
      }
      e.preventDefault();
    });


    $('.confirm').on('click', function (ev) {
        var $target = $(ev.target),
            msg = 'Weet je het zeker?';

        if ($target.data('message')) {
            msg = $target.data('message');
        }

        if (!confirm(msg)) {
            console.log('stopping event..');
            ev.stopPropagation();
            return false;
        }

        console.log('go event go!.. ');
    });

    // $('.tooltips').on('click', (ev) => {
    //   console.info(ev.target);
    //   $(ev.target).find('span.tooltips__inner')[0].style.display = 'block';
    // });


  });

  var onResize = null;
  function reinit() {
    console.info('window resize!');
    clearTimeout(onResize);
    onResize = setTimeout(function () {
      readMore();
      calculateHeightTab();
      resetClassesTabDesktop();
    }, 500); // this could need some tweaking
  }

  $(window).on('orientationchange', reinit);
  // $(window).on('resize', reinit);

  $(window).on('load', () => {
    console.info('window loaded');
    window.setTimeout(() => {
      if (document.location.hash && $(document.location.hash).length >= 1) {
        console.info('scroll to target: '+ document.location.hash);
        let offset = $(document.location.hash).offset();

        try {
          // this does not work for IE
          if (!window.matchMedia('only screen and (max-width: 768px)').matches) {
            offset.top -= 150;
          }
        } catch (err) {
          console.error(err);
        }

        scrollTo(offset);
      }
    }, 300);
  });

})($);



